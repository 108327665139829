import { useState } from 'react';

import { Skeleton } from '@/components/Skeleton';
import { getColorFromSCSS } from '@/helpers/styles/getColorFromSCSS';
import { type TMediaSet } from '@/infra/types/common';

import useAcceptableSrc, { type TUseAcceptableSrcArgs } from '../hooks/useAcceptableSrc';

/* Can't just use the "srcSet" attribute for two reasons:
Firstly, when reducing the element size, Google Chrome does not return a smaller image, but uses a larger one because
it has already been loaded.
Secondly, Google Chrome loads images much larger than necessary (with a large margin) and it cannot be controlled */

export const SKELETON_PROPS = {
  baseColor: getColorFromSCSS('gray-205'),
  fill: true,
  height: '100%',
  rounded: true,
} as const;

type TProps = Omit<JSX.IntrinsicElements['img'], 'alt' | 'src' | 'srcSet'> &
  TMediaSet &
  Pick<TUseAcceptableSrcArgs, 'chooseSize' | 'containerRef' | 'isScreenBased' | 'sizeThreshold'>;

const ImageSet = (props: TProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const { alt, chooseSize, className, containerRef, isScreenBased, sizeThreshold, srcSet, ...restProps } = props;
  const args = { chooseSize, containerRef, isScreenBased, sizeThreshold, srcSet };
  const { isDisabled, setRef, src } = useAcceptableSrc<HTMLImageElement>(args);

  if (isDisabled) return null;

  const onLoad = () => setIsLoading(false);

  return (
    <>
      {isLoading && <Skeleton {...SKELETON_PROPS} />}
      <img {...restProps} alt={alt} className={className} hidden={isLoading} onLoad={onLoad} ref={setRef} src={src} />
    </>
  );
};

export default ImageSet;
