import { useRef, useState } from 'react';

import cn from 'classnames';

import type { TMediaSrcSet } from '@/infra/types/common';
import type { TOptional } from '@/types/common';

import { Skeleton } from '@/components/Skeleton';

import useAcceptableSrc, { type TUseAcceptableSrcArgs } from '../hooks/useAcceptableSrc';

export type TVideoControls = {
  load: () => void;
  pause: () => void;
  play: () => void;
};

export const makeVideoControls = (
  ref: React.RefObject<HTMLMediaElement>,
  setIsPlayed: (value: boolean) => void,
): TVideoControls => ({
  load: () => ref.current?.load(),
  pause: () => {
    ref.current?.pause();
    setIsPlayed(false);
  },
  play: () => {
    ref.current?.play();
    setIsPlayed(true);
  },
});

type TProps = Omit<JSX.IntrinsicElements['video'], 'src'> &
  Pick<TUseAcceptableSrcArgs, 'chooseSize' | 'isScreenBased' | 'sizeThreshold'> & {
    children?: React.ReactNode;
    className?: TOptional<string>;
    fallback?: React.ReactNode;
    onReady?: TOptional<(controls: TVideoControls) => void>;
    srcSet: TMediaSrcSet;
  };

const VideoSet = ({
  autoPlay,
  children,
  chooseSize,
  className,
  fallback = <Skeleton baseColor="#eaeaea" fill rounded />,
  isScreenBased,
  onReady,
  sizeThreshold,
  srcSet,
  ...restProps
}: TProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isPlayed, setIsPlayed] = useState<boolean>(!!autoPlay);
  const args = { chooseSize, containerRef, isScreenBased, sizeThreshold, srcSet };
  const { ref, setRef, src } = useAcceptableSrc<HTMLVideoElement>(args);

  const [isLoading, setIsLoading] = useState(true);
  const onLoadedData = () => {
    if ((ref.current?.readyState ?? 0) >= HTMLMediaElement.HAVE_CURRENT_DATA) {
      onReady?.(makeVideoControls(ref, setIsPlayed));
      setIsLoading(false);
    }
  };

  const showFallback = !src || isLoading;

  return (
    <div className={cn(className, { _playing: isPlayed })} ref={containerRef}>
      {showFallback && fallback}
      {!!src && (
        <video
          autoPlay={isPlayed}
          onLoadedData={onLoadedData}
          ref={setRef}
          src={src}
          {...restProps}
          hidden={isLoading}
          playsInline
        />
      )}
      {!showFallback && children}
    </div>
  );
};

export default VideoSet;
