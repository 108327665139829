'use client';

import { useCallback } from 'react';

import cn from 'classnames';

import type { TLink } from '@/infra/types/common';

import {
  PromoBannerPlaceId,
  PromoBannerType,
  type TPromoBannerAny,
  type TPromoBannerBillboardProps,
  type TPromoBannerDoubleProps,
  type TPromoBannerFeaturetteProps,
  type TPromoBannerLeaderboardProps,
  type TPromoBannerMarqueeProps,
  type TPromoBannerOnClick,
  type TWithOnClick,
} from '@/features/promoBanners/types';
import tagmanager from '@/tagmanager';

import Billboard from '../Billboard';
import Double from '../Double';
import Featurette from '../Featurette';
import Leaderboard from '../Leaderboard';
import Marquee from '../Marquee';
import usePromoBannersProps from './usePromoBannersProps';

import './PromoBannerPlace.scss';

export const makeBanner = (props: TPromoBannerAny, onClick: TPromoBannerOnClick) => {
  switch (props.type) {
    case PromoBannerType.billboard:
      return <Billboard {...(props as TPromoBannerBillboardProps)} onClick={onClick} />;
    case PromoBannerType.double:
      return <Double {...(props as TPromoBannerDoubleProps)} onClick={onClick} />;
    case PromoBannerType.featurette:
      return <Featurette {...(props as TPromoBannerFeaturetteProps)} onClick={onClick} />;
    case PromoBannerType.leaderboard:
      return <Leaderboard {...(props as TPromoBannerLeaderboardProps)} onClick={onClick} />;
    case PromoBannerType.marquee:
      return <Marquee {...(props as TPromoBannerMarqueeProps)} onClick={onClick} />;
  }
};

type TProps = TWithOnClick<{
  placeId: PromoBannerPlaceId;
  type?: PromoBannerType;
}> &
  (
    | {
        className?: never;
        isFrameless?: boolean;
      }
    | {
        className?: string;
        isFrameless?: never;
      }
  );

const PromoBannerPlace = ({ className, isFrameless, onClick, placeId, type }: TProps) => {
  const props = usePromoBannersProps(placeId, type);

  const trackedOnClick = useCallback(
    (link: Partial<TLink>, clickDetails?: unknown) => {
      onClick?.(link, clickDetails);
      tagmanager.tracker.promoBanners.onClick(props, clickDetails);
    },
    [onClick],
  );

  if (props) {
    const banner = makeBanner(props, trackedOnClick);
    if (banner) {
      return isFrameless ? (
        banner
      ) : (
        <div className={cn('PromoBannerPlace', `_${placeId}`, `_${props.type}`, className)}>{banner}</div>
      );
    }
  }
};

export default PromoBannerPlace;
