import { Children, useCallback, useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import isEqual from 'lodash/isEqual';

import getFreeScrollSpace from '@/helpers/getFreeScrollSpace';
import { type TOrientation } from '@/types/common';

import './styles.scss';

// Use "--grabbable-*" css variables on the parent node(s) to tune the appearance

const WHEEL_FACTOR = 1 / 4;

type TProps = React.ComponentProps<'div'> & {
  children: React.ReactNode;
  className?: string;
  node?: 'div' | 'section';
  sliding?: TOrientation;
  withShadows?: boolean;
};

const Grabbable = ({ children, className, node: Node = 'div', sliding, withShadows, ...restRootProps }: TProps) => {
  type TPoint = { left: number; top: number; x: number; y: number };
  const initialPosition = useRef<TPoint>({} as TPoint);
  const ref = useRef<HTMLDivElement>(null);
  const isMovementAllowed = useRef<boolean>(false);
  const isMovementRegistered = useRef<boolean>(false);
  const [freeSpace, setFreeSpace] = useState<Record<string, boolean>>();

  const hasChildren = !!Children.count(children);

  const onMouseMove = useCallback((event: Event) => {
    if (!isMovementAllowed.current) {
      return;
    }
    const clientX = (event as PointerEvent).clientX ?? (event as TouchEvent).touches?.[0]?.clientX;
    const clientY = (event as PointerEvent).clientY ?? (event as TouchEvent).touches?.[0]?.clientY;
    const dx = clientX - initialPosition.current!.x;
    const dy = clientY - initialPosition.current!.y;
    ref.current!.scrollLeft = initialPosition.current!.left - dx;
    ref.current!.scrollTop = initialPosition.current!.top - dy;
    isMovementRegistered.current = true;
  }, []);

  const onMouseDown = useCallback((event: Event) => {
    isMovementAllowed.current = true;
    event.preventDefault();
    event.stopImmediatePropagation();
    initialPosition.current = {
      left: ref.current!.scrollLeft,
      top: ref.current!.scrollTop,
      x: (event as PointerEvent).clientX ?? (event as TouchEvent).touches?.[0]?.clientX,
      y: (event as PointerEvent).clientY ?? (event as TouchEvent).touches?.[0]?.clientY,
    };
  }, []);

  const onClick = useCallback((event: Event) => {
    if (isMovementRegistered.current) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }, []);

  const onMouseUp = useCallback(() => {
    isMovementRegistered.current = false;
    isMovementAllowed.current = false;
  }, []);

  const onWheel = useCallback((event: React.WheelEvent) => {
    if (!isMovementRegistered.current) {
      const node = ref.current!;
      if (node.scrollWidth <= node.offsetWidth) {
        return;
      }
      const { deltaX, deltaY } = event;
      const delta = Math.abs(deltaY) > Math.abs(deltaX) ? deltaY : deltaX;
      if ((delta > 0 && node.scrollLeft + node.offsetWidth < node.scrollWidth) || (delta < 0 && node.scrollLeft > 0)) {
        event.preventDefault();
      }
      node.scrollLeft += WHEEL_FACTOR * delta;
    }
  }, []);

  useEffect(() => {
    const node = ref?.current;
    if (node && hasChildren) {
      const events = {
        click: onClick,
        mousedown: onMouseDown,
        mouseleave: onMouseUp,
        mousemove: onMouseMove,
        mouseup: onMouseUp,
        ...(sliding === 'horizontal' ? { wheel: onWheel } : undefined),
      };
      Object.entries(events).forEach(([event, listener]) => node.addEventListener(event, listener as EventListener));
      return () =>
        Object.entries(events).forEach(([event, listener]) =>
          node.removeEventListener(event, listener as EventListener),
        );
    }
  }, [hasChildren, sliding]);

  useEffect(() => {
    const node = ref?.current;
    if (node && hasChildren && withShadows) {
      const updateFreeSpace = () => {
        const scrollSpace = getFreeScrollSpace(node!, sliding || 'vertical');
        if (scrollSpace) {
          const next = Object.fromEntries(Object.entries(scrollSpace).map(([key, value]) => [`_${key}`, value > 0]));
          if (!isEqual(next, freeSpace)) setFreeSpace((prev) => (isEqual(next, prev) ? prev : next));
        }
      };

      updateFreeSpace();

      node.addEventListener('scroll', updateFreeSpace);
      window.addEventListener('resize', updateFreeSpace);
      return () => {
        node.removeEventListener('scroll', updateFreeSpace);
        window.removeEventListener('resize', updateFreeSpace);
      };
    }
  }, [hasChildren, withShadows, sliding, freeSpace]);

  return (
    hasChildren && (
      <Node
        {...restRootProps}
        className={!withShadows ? cn('Grabbable', className) : cn('Grabbable__outer', freeSpace)}
        ref={!withShadows ? ref : undefined}
      >
        {!withShadows && children}
        {withShadows && (
          <div className={cn('Grabbable', className)} ref={ref}>
            {children}
          </div>
        )}
      </Node>
    )
  );
};

export default Grabbable;
