import { type RefCallback, type RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import findLast from 'lodash/findLast';
import isEmpty from 'lodash/isEmpty';

import type { TMediaSrcSet } from '@/infra/types/common';
import type { TOptional } from '@/types/common';

import subscribeOnDOMEvent from '@/helpers/subscribeOnDOMEvent';
import useOnWindowResize from '@/hooks/useOnWindowResize';

import getOrderedNumericKeys from '../helpers/getOrderedNumericKeys';

export const DEFAULT_SIZE_THRESHOLD = 50;

export const chooseSizeDefault = (orderedSizes: number[], value: number, threshold?: number): TOptional<number> => {
  const factor = 1 - (0 <= (threshold ?? NaN) && (threshold ?? NaN) <= 100 ? threshold! : DEFAULT_SIZE_THRESHOLD) / 100;
  return findLast(
    orderedSizes,
    (size, i) => i === 0 || Math.round(size - factor * (size - orderedSizes[i - 1]!)) <= value,
  );
};

export const getAvailableWidth = (node: TOptional<HTMLElement | null>, isOuter?: boolean): number => {
  if (node) {
    const css = getComputedStyle(node);
    const offsets = [];
    if (!isOuter) offsets.push(css.paddingLeft, css.paddingRight);
    else if (css.position !== 'absolute') offsets.push(css.marginLeft, css.marginRight);
    return node.offsetWidth - offsets.map(fromCssLength).reduce((acc, item) => acc + item, 0);
  }
  return 0;
};

// The simplest implementation
export const fromCssLength = (length: string): number => parseFloat(length);

export type TMediaNode = HTMLImageElement | HTMLMediaElement;

export type TUseAcceptableSrcArgs = {
  chooseSize?: (orderedSizes: number[], value: number) => TOptional<number>;
  containerRef?: RefObject<HTMLElement>;
  isScreenBased?: boolean;
  sizeThreshold?: number;
  srcSet: TMediaSrcSet;
};

export type TUseAcceptableSrc<E> = {
  isDisabled?: boolean;
  ref: RefObject<E>;
  setRef: RefCallback<E>;
  src: TOptional<string>;
};

const useAcceptableSrc = <E extends TMediaNode>(args: TUseAcceptableSrcArgs): TUseAcceptableSrc<E> => {
  const { chooseSize, containerRef, isScreenBased, sizeThreshold, srcSet } = args ?? {};
  const [failedUrls, setFailedUrls] = useState<string[]>();
  const ref = useRef<E>();
  const refCleanup = useRef<() => void>();

  const isDisabled = useMemo(() => isEmpty(srcSet), [srcSet]);

  const onError = useCallback((event: Event) => {
    const { currentSrc: url } = (event.target || {}) as TMediaNode;
    if (url) setFailedUrls((prev) => (!prev?.includes(url) ? [...(prev || []), url] : prev));
  }, []);

  const setRef = (node: E) => {
    refCleanup.current?.();
    if (node) {
      ref.current = node;
      refCleanup.current = subscribeOnDOMEvent(node, 'error', onError);
    }
  };

  useEffect(() => () => refCleanup.current?.(), []);

  const src = useOnWindowResize<TOptional<string>>(
    ({ width: screenWidth }) => {
      const width = isScreenBased
        ? screenWidth
        : getAvailableWidth(containerRef?.current || ref?.current?.parentElement);
      const sizes = getOrderedNumericKeys(srcSet).filter((size) => !failedUrls?.includes(srcSet[`${size!}`]!));
      if (sizes.length) {
        const size = chooseSize ? chooseSize(sizes, width) : chooseSizeDefault(sizes, width, sizeThreshold);
        return srcSet[`${size ?? sizes[0]!}`];
      }
    },
    [chooseSize, failedUrls, isScreenBased, sizeThreshold, srcSet],
  );

  return { isDisabled, ref: ref as RefObject<E>, setRef, src };
};

export default useAcceptableSrc;
